import { apiSlice } from '../../apiSlice';

export const dealsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsersByPartner: builder.mutation({
      query: (partner) => ({
        url: `/api/users/getallusersbypartner/${partner}`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/api/users/deleteuser/${id}`,
        method: 'DELETE',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updateUserRole: builder.mutation({
      query: (body) => ({
        url: `/api/users/edituserrole`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updateUserPasswordAdminPanel: builder.mutation({
      query: (body) => ({
        url: `/api/users/edituserpasswordadminpanel`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getUsersWazuh: builder.mutation({
      query: () => ({
        url: '/api/users/wazuhusers',
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    getRecentUsersByCountry: builder.mutation({
      query: () => ({
        url: `/api/users/getrecentusersbycountry`,
        method: 'GET',
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    updateAccountPassword: builder.mutation({
      query: (body) => ({
        url: `/api/users/editaccountpassword/${body.id}`,
        method: 'PUT',
        body: body,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
  }),
});

export const {
  useGetAllUsersByPartnerMutation,
  useDeleteUserMutation,
  useUpdateUserRoleMutation,
  useUpdateUserPasswordAdminPanelMutation,
  useGetUsersWazuhMutation,
  useGetRecentUsersByCountryMutation,
  useUpdateAccountPasswordMutation
} = dealsApiSlice;
